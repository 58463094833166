import React from "react";
import BookDemoButton from "./Webinar/BookDemoButton";

interface HeroButtonProps {
    buttontext?: string;
    showBookDemoBtn?: boolean;
    signupButtonClass?: string;
    bookDemoButtonClass?: string;
    alignLayout?: string;
    href?: string;
    onClick?: () => void;
    isArabic?: boolean;
}
const HeroButton: React.FC<HeroButtonProps> = ({
    buttontext = "Start Free Trial",
    showBookDemoBtn,
    signupButtonClass,
    bookDemoButtonClass = "py-4",
    alignLayout = "flex-row",
    href = "https://app.gallabox.com/signup",
    onClick,
    isArabic,
}) => {
    return (
        <div className={`lg:pt-6 text-center flex lg:gap-6 gap-3 mx-auto md:mx-0 ${alignLayout}`}>
            {onClick ? (
                <button
                    onClick={onClick}
                    className={`${
                        signupButtonClass
                            ? signupButtonClass
                            : "px-4 border py-2 border-solid border-blue-500  text-blue-500 bg-white rounded-md hover:no-underline focus:outline-none hover:shadow-2xl text-base font-medium flex items-center"
                    }`}
                >
                    {buttontext}
                </button>
            ) : (
                <a
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                    className={`${
                        signupButtonClass
                            ? signupButtonClass
                            : "px-4 border py-2 border-solid border-blue-500  text-blue-500 bg-white rounded-md hover:no-underline focus:outline-none hover:shadow-2xl text-base font-medium flex items-center"
                    }`}
                >
                    {buttontext}
                </a>
            )}
            {showBookDemoBtn ? <BookDemoButton className={bookDemoButtonClass} isArabic={isArabic} /> : null}
        </div>
    );
};

export default HeroButton;
