import React, { useMemo } from "react";
import CtaButton from "../CtaButton";
import { FeatureCardMediaProps } from "./FeatureCardMedia";
import { FeatureCardMediaPositionProps } from ".";
import Image from "next/image";
import { IconType } from "react-icons/lib";

type MeritsLists = {
    icon?: IconType;
    image?: string;
    listItem: string | JSX.Element;
};
export interface FeatureCardContentProps extends FeatureCardMediaProps, FeatureCardMediaPositionProps {
    chipTitle?: string;
    title: string | JSX.Element;
    description?: string;
    meritsLists?: MeritsLists[];
    buttonText?: string;
    navLink?: string;
    isArabic?: boolean;
}
export const isJSXElement = (elm: string | JSX.Element | undefined): elm is JSX.Element => {
    return typeof elm !== "string";
};
const FeatureCardContent: React.FC<FeatureCardContentProps> = (props) => {
    const { chipTitle, title, description, meritsLists, buttonText, navLink, isArabic } = props;
    const titleComponent = useMemo(() => {
        if (isJSXElement(title)) return title;
        return <h2 className={`text-3xl font-bold text-gray-700 ${isArabic ? "lg:text-right" : "lg:text-left"} text-center`}>{title}</h2>;
    }, [title, isArabic]);
    const descriptionComponent = useMemo(() => {
        if (isJSXElement(description)) return description;
        return <p className={`text-base font-normal text-gray-500 ${isArabic ? "lg:text-right" : "lg:text-left"} text-center`}>{description}</p>;
    }, [description, isArabic]);
    return (
        <div className="lg:w-1/2 w-fit mx-4">
            {chipTitle && <div className={`text-xl font-medium text-blue-500 ${isArabic ? "lg:text-right" : "lg:text-left"} text-center`}>{chipTitle}</div>}
            <div className="mb-8">{titleComponent}</div>
            <div className="mb-4">{descriptionComponent}</div>
            {meritsLists &&
                meritsLists.map((item, index) => (
                    <div key={index}>
                        {item.image && (
                            <div className="flex gap-3 items-center my-4">
                                <div className=" w-6 h-6 flex items-center justify-center  ">
                                    <Image src={item.image} alt="" width={10} height={10} layout="fixed" className="object-contain bg-gray-500" />
                                </div>
                                <p className="w-full text-base font-normal text-gray-500">{item.listItem}</p>
                            </div>
                        )}
                        {item.icon && (
                            <div className="flex gap-3 items-center my-4">
                                <div className="bg-green-400 text-white w-12 h-12 rounded-2xl flex items-center justify-center flex-shrink-0">
                                    <item.icon size="24px" />
                                </div>
                                <p className="text-base font-normal text-gray-500">{item.listItem}</p>
                            </div>
                        )}
                        {!item.icon && !item.image && (
                            <div className="flex gap-3 items-start mb-2">
                                <div className="flex justify-center flex-shrink-0">
                                    <Image
                                        className="flex justify-center"
                                        width={21}
                                        height={21}
                                        loading="lazy"
                                        src="https://assets.gallabox.com/gb-home/content-with-image/green-tick-circle.svg"
                                        alt="Encircled Green Tick"
                                    />
                                </div>
                                <p className="text-base font-normal text-gray-500">{item.listItem}</p>
                            </div>
                        )}
                    </div>
                ))}
            {buttonText && navLink && <CtaButton type="small" text={buttonText} customClass="mt-10" href={navLink} />}
        </div>
    );
};

export default FeatureCardContent;
