import FeatureCardContent, { FeatureCardContentProps } from "@components/FeatureCardV2/FeatureCardContent";
import React from "react";
import FeatureCardMedia, { FeatureCardMediaProps } from "./FeatureCardMedia";

export interface FeatureCardMediaPositionProps {
    mediaPosition: "left" | "right";
}

export interface FeatureCardItemProps extends FeatureCardContentProps, FeatureCardMediaProps, FeatureCardMediaPositionProps {}

interface FeatureCardProps {
    cardData: FeatureCardItemProps[];
    isArabic?: boolean;
    mobileView?: "content-first" | "image-first";
}

const FeatureCard: React.FC<FeatureCardProps> = (props) => {
    const { cardData, mobileView = "image-first", isArabic } = props;
    return (
        <div className="lg:max-w-6xl lg:mx-auto w-full">
            {cardData.map((inputContent, index) => {
                return (
                    <div
                        key={index}
                        className={`lg:max-w-6xl lg:mx-auto w-full flex lg:flex-row lg:pb-0 pb-10 ${
                            mobileView === "content-first"
                                ? inputContent.mediaPosition === "left"
                                    ? "flex-col-reverse"
                                    : "flex-col"
                                : inputContent.mediaPosition === "left"
                                ? "flex-col"
                                : "flex-col-reverse"
                        } lg:items-center lg:gap-6 gap-10 mt-20`}
                    >
                        {inputContent.mediaPosition === "left" && (
                            <>
                                <FeatureCardMedia {...inputContent} />
                                <FeatureCardContent {...inputContent} isArabic={isArabic} />
                            </>
                        )}
                        {inputContent.mediaPosition === "right" && (
                            <>
                                <FeatureCardContent {...inputContent} isArabic={isArabic} />
                                <FeatureCardMedia {...inputContent} />
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default FeatureCard;
